// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"

require("alpinejs")
import '../stylesheets/application.scss'

require("chartkick")
require("chart.js")

window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)
Chartkick.options = {
  library: { 
    legend: {
      display: false,
      align: 'start'
    },
    animation: { 
      duration: 2000, 
      easing: 'easeOutQuart' 
    },
    deferred: {
      xOffset: '60%',
      delay: 500
    }
  },
}


Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
  // console.log('turbolinks loaded');
});

